export const UNIVERSE_PATHS = {
  home: "",
  page: "",
  article: "/artikkel",
  product: "/produkter",
  book: "/boker",
  contributor: "/forfatter",
  subscription: "/abonnement",
  checkout: "/utsjekk",
  receipt: "/kvittering",
  "my-page": "/min-side",
} as const;

// export const GLOBAL_PATHS = {} as const;
