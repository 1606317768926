import { getPageLink } from "@/navigation/selectors";
import { Link } from "./types";

export const getPageLinkFromLinkConfig = ({
  link,
  universeTag,
}: {
  link?: Partial<Pick<Link, "slug" | "external" | "type" | "cartItems">> | null;
  universeTag?: string;
}) => {
  const { params, searchParams } = getCartItemsUrl(link?.cartItems);

  return getPageLink({
    pageType: link?.type ?? "home",
    url: link?.external,
    params: link?.slug ?? params,
    searchParams,
    universeTag,
  });
};

export const getCartItemsUrl = (cartItems?: Link["cartItems"]) => {
  const subscriptionSku = cartItems?.find(
    (item) => item._type === "subscription",
  )?.sku;

  const searchParams = cartItems?.reduce<{
    [key: string]: string | string[] | undefined;
  }>((acc, cur) => {
    if (cur._type === "subscription") return acc;
    if (cur.startBook) return { ...acc, initialSku: cur.startBook };
    return { ...acc, item: acc.item ? [...acc.item, cur.sku] : [cur.sku] };
  }, {});

  return {
    params: subscriptionSku,
    searchParams,
  };
};
