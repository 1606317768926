import type * as IconTypes from "react-icons/pi";
import { SanityIconData } from "./types";

type IconName = keyof typeof IconTypes;

function capitalizeFirstLetter(string?: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getIconName = ({ icon, weight }: SanityIconData): IconName =>
  `Pi${capitalizeFirstLetter(icon)}${
    weight && weight !== "regular" ? capitalizeFirstLetter(weight) : ""
  }` as IconName;
