import { ImageUrlBuilderOptions } from "@sanity/image-url/lib/types/types";
import { ImageProps } from "next/image";
import { CSSProperties } from "react";
import { imageUrlFor } from "../sanity.client";
import { SanityImage } from "./types";
import { ImageTheme, PaletteOptions, Theme } from "@/theming/types";

export const getImageProps = ({
  asset,
  hotspot,
  crop,
  altText,
  width = 1920,
  height = getImageAspectRatio({ width: 1920, aspectRatio: "16/9" }).height,
  unoptimized = true,
  ...options
}: SanityImage & Omit<Partial<ImageUrlBuilderOptions>, "crop">): Required<
  Pick<ImageProps, "src" | "alt" | "placeholder" | "width" | "height">
> &
  Pick<ImageProps, "blurDataURL" | "unoptimized"> => ({
  src: imageUrlFor({ asset, crop, hotspot })
    .withOptions(options)
    .width(width)
    .height(height)
    .fit("crop")
    .format("webp")
    .auto("format")
    .url(),
  alt: altText ?? asset?.altText,
  placeholder: "blur",
  blurDataURL: asset?.metadata?.lqip,
  width,
  height,
  unoptimized,
});

export const getImageBackgroundUrl = ({
  asset,
  hotspot,
  crop,
  width = 1920,
  height = getImageAspectRatio({ width: 1920, aspectRatio: "16/9" }).height,
  ...options
}: SanityImage & Omit<Partial<ImageUrlBuilderOptions>, "crop">) => {
  return imageUrlFor({ asset, crop, hotspot })
    .withOptions(options)
    .width(width)
    .height(height)
    .format("webp")
    .auto("format")
    .url();
};

export const getImageColor = (
  image: SanityImage,
  type: PaletteOptions = "lightMuted",
): ImageTheme => {
  if (type === "muted" && image?.asset.metadata.palette?.muted)
    return {
      background: image.asset.metadata.palette.muted.background,
      text: image.asset.metadata.palette.muted.foreground,
    };
  if (type === "lightMuted" && image?.asset.metadata.palette?.lightMuted)
    return {
      background: image.asset.metadata.palette.lightMuted.background,
      text: image.asset.metadata.palette.lightMuted.foreground,
    };
  if (type === "darkMuted" && image?.asset.metadata.palette?.darkMuted)
    return {
      background: image.asset.metadata.palette.darkMuted.background,
      text: image.asset.metadata.palette.darkMuted.foreground,
    };
  return {
    background: "#fffff",
    text: "#00000",
  };
};

export const generateThemeFromImageTheme = (imageTheme?: ImageTheme): Theme => {
  const backgroundHex = imageTheme?.background ?? "#fffff";
  const textHex = imageTheme?.text ?? "#00000";
  return {
    background: {
      hex: backgroundHex,
      hsl: "",
      hsv: "",
      rgb: "",
    },
    text: {
      hex: textHex,
      hsl: "",
      hsv: "",
      rgb: "",
    },
  };
};

const SIZES = Object.entries({
  raw: -1,
  lg: 1440,
  md: 1200,
  sm: 1024,
  xs: 768,
});

export const getImageBackgroundStyle = (
  image?: SanityImage & Omit<Partial<ImageUrlBuilderOptions>, "crop">,
): CSSProperties => {
  if (!image) return {};
  return SIZES.reduce<CSSProperties>((acc, [key, width]) => {
    return {
      ...acc,
      [`--bg-img-${key}`]: `url(${
        width === -1
          ? getImageBackgroundUrl(image)
          : getImageBackgroundUrl({
              ...image,
              ...getImageAspectRatio({
                width,
                aspectRatio: image.asset.metadata.dimensions.aspectRatio,
              }),
            })
      })`,
    };
  }, {});
};

type AspectRatio =
  | { height: number; width?: null }
  | { height?: null; width: number };

export const getImageAspectRatio = ({
  height,
  width,
  aspectRatio,
}: {
  aspectRatio: "16/9" | "1/1" | "4/3" | number;
} & AspectRatio) => {
  const ratio =
    typeof aspectRatio === "number"
      ? aspectRatio
      : aspectRatio
          .split("/")
          .reduce((acc, cur) => (acc ? acc / parseInt(cur) : parseInt(cur)), 0);
  if (height)
    return {
      height,
      width: Math.round(height * ratio),
    };
  if (width)
    return {
      width,
      height: Math.round(width / ratio),
    };

  return {
    height: 0,
    width: 0,
  };
};
