import { PortableTextTypeComponent } from "@portabletext/react";
import { TypedObject } from "@portabletext/types";
import { Link as LinkType } from "../../cms/links/types";
import CMSLink from "@/cms/links/components/CMSLink";

export const LinkBlock: PortableTextTypeComponent<LinkType & TypedObject> = ({
  value,
}) => {
  return (
    <CMSLink
      data={value}
      parentIndex={0}
      parentSection=""
      itemIndex={0}
      className="inline-flex w-fit self-center sm:self-start"
    />
  );
};
