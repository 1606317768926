import { SanityIcon } from "@/cms/icons/Icon";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import classNames from "classnames";
import { CSSProperties, FC } from "react";
import { Link as LinkConfig } from "../types";
import { getPageLinkFromLinkConfig } from "../selectors";
import { getTrackingDataset } from "@/tracking/utils";
import { TrackingLink } from "@/tracking/types";
import { ContentData } from "@/cms/types";
import { Link, Anchor, LinkButton } from "@/navigation";

export type LinkProps = ContentData<Omit<LinkConfig, "variant">> &
  Omit<TrackingLink, "itemType" | "href"> &
  JSX.IntrinsicElements["a"] & {
    data: {
      variant?: "custom" | LinkConfig["variant"];
    };
  };

const getLinkComponentFromVariant = (variant: LinkProps["data"]["variant"]) => {
  if (variant === "button") return LinkButton;
  if (variant === "link") return Link;
  return Anchor;
};

export const CMSLink: FC<LinkProps> = ({
  data: {
    variant,
    theme,
    cartItems,
    contentAlignment,
    icon,
    title,
    microcopy,
    universe,
    ...link
  },
  parentIndex,
  parentSection,
  itemIndex,
  className,
  children,
  ...rest
}) => {
  const { href, linkAs } = getPageLinkFromLinkConfig({
    link: { ...link, cartItems },
    universeTag: universe?.tag,
  });
  const tracking = getTrackingDataset<TrackingLink>({
    itemType: variant ?? "",
    href: href?.toString() ?? "",
    parentIndex,
    parentSection,
    itemIndex,
  });

  const LinkComponent = getLinkComponentFromVariant(variant);

  return (
    <div>
      <LinkComponent
        {...tracking}
        {...rest}
        href={href}
        linkAs={linkAs}
        className={classNames(
          {
            "bg-primary cvr-button border-2 border-[unset] text-[var(--fg-primary)] !no-underline shadow-none duration-150 ease-in-out hover:border-2 hover:bg-[var(--hover)] hover:text-[var(--fg-primary)]":
              variant === "button",
            "inline-flex items-center gap-1 underline underline-offset-8 hover:no-underline focus:rounded-lg focus:border-4 focus:border-black":
              variant === "link",
            "border-primary-contrast": variant === "button" && theme,
            "text-primary-contrast border-2 border-[unset] shadow-none hover:border-2 hover:bg-[var(--hover)] hover:text-[unset]":
              variant === "button" && !theme,
          },
          className,
        )}
        style={
          {
            "--text-hover": `color-mix(in srgb, var(--text-primary-contrast) 80%, black)`,
            "--hover": `color-mix(in srgb, var(--bg-primary) 80%, black)`,
            ...(variant === "button" && theme
              ? getCSSPropertiesTheme(theme)
              : {}),
          } as CSSProperties
        }
      >
        <div
          className={classNames("flex gap-2", {
            "flex-row-reverse": contentAlignment === "left",
          })}
        >
          {title && microcopy ? `${title} *` : title}
          {icon && <SanityIcon data={{ ...icon }} size={24} />}
        </div>
        {children}
      </LinkComponent>
      {microcopy ? <p>{`* ${microcopy}`}</p> : null}
    </div>
  );
};

export default CMSLink;
