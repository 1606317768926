import { PageTypes, UniversePages } from "./types";
import { UNIVERSE_PATHS } from "./constants";

export const getPageLink = ({
  pageType,
  universeTag,
  searchParams,
  params,
  url,
}: {
  pageType: PageTypes;
  universeTag?: string;
  params?: string;
  searchParams?: { [key: string]: string | string[] | undefined };
  url?: string;
}) => {
  if (pageType === "external") return { href: url ?? "", linkAs: undefined };
  const pathname = getPathnameFromPageType({ pageType, params, universeTag });
  const query = getQueryParamsString(searchParams);
  const search = query.length ? `?${query}` : "";
  return getUniverseHrefProps({ href: `${pathname}${search}`, universeTag });
};

const getPathnameFromPageType = ({
  pageType,
  params,
  universeTag,
}: {
  pageType: PageTypes;
  universeTag?: string;
  params?: string;
}) => {
  const pathParams = params ? `/${params}` : "";
  if (isUniversePage(pageType) && universeTag)
    return `/${universeTag}${UNIVERSE_PATHS[pageType]}${pathParams}`;
  //  if (isGlobalPage(pageType)) return `${GLOBAL_PATHS[pageType]}${pathParams}`;

  return `/${pageType}${pathParams}`;
};

const isUniversePage = (pageType: PageTypes): pageType is UniversePages =>
  Object.keys(UNIVERSE_PATHS).includes(pageType);

// const isGlobalPage = (pageType: PageTypes): pageType is GlobalPages =>
//   Object.keys(GLOBAL_PATHS).includes(pageType);

const getUniverseHrefProps = ({
  href,
  universeTag,
}: {
  href: string;
  universeTag?: string;
}): { href: string; linkAs: string } => {
  const linkAs = href.replace(universeTag ? `/${universeTag}` : "", "");
  return {
    href,
    linkAs,
  };
};

export const getQueryParamsString = (record?: {
  [key: string]: string | string[] | undefined;
}): string =>
  record
    ? Object.entries(record)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";
