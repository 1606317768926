import { FC } from "react";
import dynamic from "next/dynamic";
import type { IconBaseProps } from "react-icons";
import { IconBase } from "react-icons";
import { getIconName } from "./getIconName";
import { SanityIconData as ContentType } from "./types";

export type SanityIconProps = Partial<{ data: ContentType } & IconBaseProps>;

/**
 * Loads icons from the react-icons/pi packages which is a phosphor icons package more friendly towards dynamic loading.
 *
 * @param param0
 * @returns
 */
const getIcon = ({ icon, weight, ...rest }: ContentType & IconBaseProps) =>
  dynamic(
    () =>
      import(`react-icons/pi`).then((mod) => {
        const iconName = getIconName({ icon, weight });
        return mod[iconName] ?? IconBase;
      }),
    { loading: () => <IconBase {...rest} /> },
  );

export const SanityIcon: FC<SanityIconProps> = ({ data, ...rest }) => {
  if (!data?.icon) return null;
  const Icon = getIcon({ ...data, ...rest });
  return <Icon aria-hidden="true" {...rest} />;
};
