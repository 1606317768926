import { PortableText, PortableTextProps } from "@portabletext/react";
import { FC } from "react";
import { IconListBlock } from "./IconListBlock/IconListBlock";
import { SectionBlocks } from "./types";
import { ValuePropositionList } from "./ValuePropositionList.tsx/ValuePropositionList";
import { QuoteBlock } from "./QuoteBlock/QuoteBlock";
import { Quote } from "../components/Quote";
import { ImageBoxBlock } from "./ImageBlock/ImageBoxBlock";
import { BlockVariableAnnotations } from "@/cms/types/objects";
import { LinkBlock } from "./Link/LinkBlock";
import { DiceQuoteBlock } from "./DiceBlock/DiceBlock";

export const getPortableTextComponentsBlock =
  (): PortableTextProps["components"] => {
    return {
      block: {
        h1: ({ children }) => (
          <h2 className="heading-01 leading-120">{children}</h2>
        ),
        h2: ({ children }) => (
          <h3 className="heading-02 leading-120">{children}</h3>
        ),
        h3: ({ children }) => (
          <h4 className="heading-03 leading-120">{children}</h4>
        ),
        h4: ({ children }) => (
          <h5 className="heading-04 leading-120">{children}</h5>
        ),
        blockquote: ({ children }) => (
          <Quote
            value={{
              text: children?.toString() ?? "",
              variant: "indented",
            }}
          />
        ),
      },
    };
  };

export type PortableTextSectionProps = Pick<
  PortableTextProps<SectionBlocks>,
  "value"
> & {
  blockVariableAnnotations?: BlockVariableAnnotations;
};

export const PortableTextSection: FC<PortableTextSectionProps> = ({
  blockVariableAnnotations,
  ...rest
}) => {
  return (
    <PortableText
      {...rest}
      components={{
        ...getPortableTextComponentsBlock(),
        marks: {
          variable: ({ value: { variable } }) => {
            if (blockVariableAnnotations) {
              //for some reason this gets a bunch of insvisible white space characters ?
              const variableWithoutWhitespace = variable.replace(
                /[\u200B-\u200D\uFEFF]/g,
                "",
              );
              return (
                <>
                  {blockVariableAnnotations[
                    variableWithoutWhitespace as keyof BlockVariableAnnotations
                  ] ?? null}
                </>
              );
            }
            return <></>;
          },
        },
        types: {
          valuePropositionList: ValuePropositionList,
          iconList: IconListBlock,
          quote: QuoteBlock,
          dice: DiceQuoteBlock,
          imageBlock: ImageBoxBlock,
          linkConfig: LinkBlock,
        },
      }}
    />
  );
};
